import React from "react";

export interface ExternalLinkProps {
  readonly children?: React.ReactNode;
  readonly href: string;
  readonly showExternalIcon?: boolean;
  readonly "data-testid"?: string;
}

export default function ExternalLink(props: ExternalLinkProps) {
  return (
    <a
      href={props.href}
      rel="noopener noreferrer"
      target="_blank"
      {...(props.showExternalIcon ? { className: "usa-link--external" } : {})}
      {...(props["data-testid"] ? { "data-testid": props["data-testid"] } : {})}
    >
      {props.children}
    </a>
  );
}
